import { NodeModel } from "@minoru/react-dnd-treeview";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetTemplatesByType } from "../../api/TemplateApi";
import { Icon } from "../../assets/Icons/IconClassNames";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import OverlayComponent from "../../components/OverlayComponent";
import SearchBar from "../../components/SearchBar";
import { NodeType, TemplateType } from "../../core/enums/enums";
import { ITemplateNodeData } from "../../core/interfaces/template";
import CreateNewTemplate from "../Document/Components/Templates/CreateNewTemplate";
import CreateNewTemplateFolder from "../Document/Components/Templates/CreateNewTemplateFolder";
import DocumentPreview from "../DocumentDirectory/DocumentPreview";
import { getParentIds } from "../DocumentDirectory/Helpers/DirectoryHelper";
import TemplateFolderContent from "./TemplateFolderContent";
import TemplatesTree from "./TemplatesTree";

interface ITemplatesDirectory {
    companyId?: string;
}

function getNodesByParentId(nodes: NodeModel<ITemplateNodeData>[], parentId: string | number): NodeModel<ITemplateNodeData>[] {
    return nodes.filter(node => node.parent === parentId);
}

const TemplatesDirectory = ({ companyId }: ITemplatesDirectory) => {
    const rootHash = "root";
    const {t} = useTranslation();
    const [selectedNodeHash, setSelectedNodeHash] = useState<string | null>(rootHash);
    const location = useLocation();
    const navigate = useNavigate();
    const {data: templates, isFetching: isFetchingTemplates} = useGetTemplatesByType(TemplateType.DOCUMENT, false);
    const [selectedNode, setSelectedNode] = useState<NodeModel<ITemplateNodeData> | null>(null);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
    const [nodePreview, setNodePreview] = useState<NodeModel<ITemplateNodeData> | null>(null);
    const [isDragAndDropEnabled, setDragAndDropEnabled] = useState(false);
    const [data, setData] = useState<NodeModel<ITemplateNodeData>[]>([]);

    const [search, setSearch] = useState('');
    const [expandedFolders, setExpandedFolders] = useState(new Set<string>());

    const handleExpandedFolders = (id: string) => {
        const idsToAdd: string[] = [];
        const item = data.find(item => item.id === id);
        if (!item) return;

        if (NodeType.FOLDER === item.data!.nodeType) {
            idsToAdd.push("" + item.id);
        }
        if (item.parent) {
            idsToAdd.push(...getParentIds(data, "" + item.parent));
        }
        setExpandedFolders(prevState => new Set([...prevState, ...idsToAdd]));
    }

    const handleExpandedFoldersToggle = (id: string) => {
        if (!expandedFolders.has(id)) {
            setExpandedFolders(new Set([...expandedFolders, id]));
        } else {
            setExpandedFolders(prevStrings => {
                const newSet = new Set(prevStrings);
                newSet.delete(id);
                return newSet;
            });
        }
    }

    const handleCloseAll = () => {
        setExpandedFolders(new Set<string>());
    }

    useEffect(() => {
        if (selectedNode) {
            handleExpandedFolders("" + selectedNode.id);
        }
    }, [selectedNode]);

    useEffect(() => {
        if (location.hash === '' || location.hash.substring(1) === rootHash) {
            setSelectedNodeHash(null);
            setSelectedNode(null);
        }
        setSelectedNodeHash(location.hash);
    }, [location.hash]);

    useEffect(() => {
        if (selectedNodeHash) {
            const found = data.find((data) => data.id === selectedNodeHash.substring(1));
            if (found !== undefined) {
                setSelectedNode(found);
            }
        } else {
            setSelectedNode(null);
        }
    }, [data, selectedNodeHash]);

    useEffect(() => {
        if (templates) {
            setData(templates.data.data);
        }
    }, [templates]);

    const handleClickTreeNode = (node: NodeModel<ITemplateNodeData>) => {
        if (node.data?.nodeType === undefined) return;
        setSearch('');
        if (NodeType.FOLDER === node.data.nodeType) {
            if (selectedNode === node) return;
            handleExpandedFolders("" + node.id);
            setSelectedNode(node);
            navigate({hash: "" + node.id});
        } else if (NodeType.DOCUMENT === node.data.nodeType) {
            setNodePreview(node);
        }
    }

    const handleStepBack = () => {
        if (!selectedNode || !!search) return;
        if (selectedNode.parent === '') {
            setSelectedNode(null);
            return navigate({hash: rootHash});
        }
        const parent = data.find((d) => d.id === selectedNode.parent);
        if (!parent) return;
        setSelectedNode(parent);
        return navigate({hash: "" + parent.id});
    }

    const getContent = (): NodeModel<ITemplateNodeData>[] => {
        return data.filter(node =>
            !!search.trim() ? node.text.toLowerCase().includes(search.toLowerCase()) :
                selectedNode ? node.parent === selectedNode.id : node.parent === ""
        );
    }

    return <MainContainer>
        <Row>
            <Col md={6} lg={8}>
                <HeaderContainer title={companyId ? t('TemplatesDirectory.TEMPLATE_LIBRARY_1') : t('TemplatesDirectory.SAMG_TEMPLATE_LIBRARY_1') }/>
            </Col>
            <Col md={6} lg={4} className="pb-3">
                <SearchBar
                    search={search}
                    setSearch={setSearch}
                    placeholder={t('TemplatesDirectory.SEARCH_TEMPLATE_1')}
                />
            </Col>
        </Row>
        <Row className="mb-4">
            <Col lg={4} className="pb-3">
                <ContentContainer>
                    <TemplatesTree
                        templatesData={data}
                        onClickNode={handleClickTreeNode}
                        selectedNode={selectedNode}
                        setDragAndDropEnabled={setDragAndDropEnabled}
                        isDragAndDropEnabled={isDragAndDropEnabled}
                        expandedFolders={Array.from(expandedFolders)}
                        handleExpandedFolders={handleExpandedFolders}
                        handleExpandedFoldersToggle={handleExpandedFoldersToggle}
                        handleCloseAll={handleCloseAll}
                    />
                </ContentContainer>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <OverlayComponent showOverlay={isDragAndDropEnabled} showSpinner={false}>
                            <ContentContainer style={{minHeight: "450px"}}>
                                <div className={"d-flex justify-content-end"}>
                                    <CustomDropdown toggleType={DropdownToggleType.DOTS} iconSize={"30px"}>
                                        <CustomDropdownItem icon={Icon.FOLDER_CREATE} text={t('TemplatesDirectory.CREATE_NEW_FOLDER_1')}
                                                            onClick={() => setShowCreateFolderModal(true)}/>
                                        <CustomDropdownItem icon={Icon.PLUS} text={t('TemplatesDirectory.TEMPLATE_CREATION_1')}
                                                            onClick={() => setShowCreateTemplateModal(true)}/>
                                    </CustomDropdown>
                                </div>
                                <TemplateFolderContent
                                    selectedNode={selectedNode}
                                    content={getContent()}
                                    onClick={handleClickTreeNode}
                                    onStepBack={handleStepBack}
                                    search={search}
                                />
                                {nodePreview && (
                                    <DocumentPreview
                                        documentId={nodePreview.data?.documentId!}
                                        onClose={() => setNodePreview(null)}
                                    />
                                )}
                            </ContentContainer>
                        </OverlayComponent>
                    </Col>
                </Row>
            </Col>
        </Row>

        {showCreateFolderModal &&
            <CreateNewTemplateFolder
                templateType={TemplateType.DOCUMENT}
                parentFolder={selectedNode}
                closeCreateNewFolderSection={() => setShowCreateFolderModal(false)}
                isFromTemplateDirectory
            />
        }
        {showCreateTemplateModal &&
            <CreateNewTemplate
                visible={showCreateTemplateModal}
                setVisible={setShowCreateTemplateModal}
                parentFolderId={selectedNode?.data?.id}
            />
        }
    </MainContainer>
};

export default TemplatesDirectory;
