import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useGetCompanyUsers } from "../../../../../../api/DocumentApi";
import CustomMultiSelect from "../../../../../../components/FormComponents/CustomMultiSelect";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import LoadingSpinner from "../../../../../../components/LoadingSpinner";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowProps, IMultiUserDataRow } from "../../../../../../core/interfaces/document";

const MultiUserDataRow = (
    {
        dataRow,
        setDataRow,
        isFieldEnabled,
        isEditMode,
        isHeaderEnabled,
        checklistViewTable,
        isAnswerMode = false
    }: IDataRowProps<IMultiUserDataRow>) => {

    const { t } = useTranslation();
    const [users, isFetching] = useGetCompanyUsers();

    const onChange = (e: { value: string, label: string }[]) => {
        setDataRow({
            ...dataRow,
            'multiUsers': e.map(v => v.value)
        });
    }

    return <>
        <When condition={!isFetching} otherwise={<LoadingSpinner />}>
            <Row className={checklistViewTable ? "" : "mt-3 mb-3 ms-2 me-2"}>
                <When condition={!checklistViewTable}>
                    <Col md={3}>
                        <CustomTextInput
                            isEditMode={isEditMode}
                            disabled={!isHeaderEnabled}
                            name={"header"}
                            placeholder={t('MultiUserDataRow.FOR_EXAMPLE_PEOPLE_1')}
                            value={getDataRowHeader(dataRow)}
                            onChange={(e) => setDataRow({
                                ...dataRow,
                                [e.target.name]: e.target.value
                            })}
                            disableAutocomplete
                        />
                    </Col>
                </When>
                <Col md={9}>
                    <Col md={12}>
                        <CustomMultiSelect
                            disabled={false}
                            isEditMode={isEditMode || isAnswerMode}
                            name={"multiUsers"}
                            options={users.map(p => ({ value: p.userId, label: p.name })) ?? []}
                            selected={users.filter(p => (dataRow.multiUsers ?? []).includes(p.userId)).map(p => ({ value: p.userId, label: p.name })) ?? []}
                            onChange={onChange}
                        />
                    </Col>
                </Col>
            </Row>
        </When>
    </>
};

export default MultiUserDataRow;