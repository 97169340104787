import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import CustomTextInput from "../../../../../../components/FormComponents/CustomTextInput";
import When from "../../../../../../components/When";
import { getDataRowHeader } from "../../../../../../core/helpers/dataRowHelper";
import { IDataRowNumericField, IDataRowProps } from "../../../../../../core/interfaces/document";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import DataRowActionButtons from "./DataRowActionButtons";


const NumericDataRow = (
    {
        dataRow,
        setDataRow,
        isHeaderEnabled,
        isFieldEnabled,
        removeDataRow,
        copyDataRow,
        checklistViewTable,
        isEditMode,
        isActive,
    }: IDataRowProps<IDataRowNumericField>) => {
    const {t} = useTranslation();
    const [internalDataRow, setInternalDataRow] = useState(dataRow);
    const debouncedDataRow = useDebounce<IDataRowNumericField>(internalDataRow);

    useEffect(() => {
        setDataRow(debouncedDataRow);
    }, [debouncedDataRow]);

    useEffect(() => {
        setInternalDataRow({
            ...internalDataRow,
            header: dataRow.header
        })
    }, [dataRow.header]);

    const onChange = (e: any) => {
        if (e.target.name === "header") {
            setInternalDataRow({
                ...internalDataRow,
                [e.target.name]: e.target.value
            })
        } else {
            const newVal = isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value);
            setInternalDataRow({
                ...internalDataRow,
                [e.target.name]: newVal
            });
        }
    }

    const validateInputValue = () => {
        if (!internalDataRow.numMinValue || !internalDataRow.numMaxValue) return "";
        if (internalDataRow.numValue === null || internalDataRow.numValue === undefined || isNaN(internalDataRow.numValue)) return "";

        if (internalDataRow.numValue < internalDataRow.numMinValue) {
            return "rgba(255, 0, 0, 0.25)";
        }
        if (internalDataRow.numValue > internalDataRow.numMaxValue) {
            return "rgba(255, 0, 0, 0.25)";
        }
        return "rgba(0, 255, 0, 0.25)";
    }

    const checklistTableView = () => (<Form.Control
        className={checklistViewTable ? "checklistTableFields" : ""}
        disabled={!isFieldEnabled}
        style={internalDataRow.numMinValue || internalDataRow.numMaxValue ? {
            backgroundColor: validateInputValue(),
            height: "100%"
        } : {height: "100%"}}
        type={"number"}
        name={"numValue"}
        value={internalDataRow.numValue ?? ""}
        onChange={onChange}
    />);

    const defaultView = () => {
        return <Row className={"mt-3 mb-3 ms-2 me-2"}>
            <When condition={!checklistViewTable}>
                <Col md={3}>
                    <CustomTextInput
                        isEditMode={isEditMode}
                        disabled={!isHeaderEnabled}
                        name={"header"}
                        placeholder={t('NumericDataRow.FOR_EXAMPLE_NUMBER_1')}
                        value={getDataRowHeader(internalDataRow)}
                        onChange={onChange}
                        disableAutocomplete
                    />
                </Col>
            </When>
            <Col md={(isEditMode && isActive) ? (checklistViewTable ? 12 : 7) : 9}>
                <Form.Control
                    className={checklistViewTable ? "tableFields" : ""}
                    disabled={!isFieldEnabled}
                    style={internalDataRow.numMinValue || internalDataRow.numMaxValue ? {backgroundColor: validateInputValue()} : {}}
                    type={"number"}
                    name={"numValue"}
                    value={internalDataRow.numValue ?? ""}
                    onChange={onChange}
                />
            </Col>
            <When condition={isEditMode && isActive}>
                <DataRowActionButtons
                    dataRow={internalDataRow}
                    removeDataRow={removeDataRow}
                    copyDataRow={copyDataRow}
                />
            </When>
        </Row>
    }

    return <When
        condition={!!checklistViewTable}
        then={checklistTableView()}
        otherwise={defaultView()}
    />
};

export default NumericDataRow;
