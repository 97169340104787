import Form from "react-bootstrap/esm/Form";
import {ITestAble} from "../../core/interfaces/common";
import When from "../When";
import {CSSProperties, useEffect, useRef, useState} from "react";

interface ICustomTextInput {
    isEditMode?: boolean;
    name: string;
    value: string | number;
    onChange: (e: any) => void;
    type?: "text" | "password" | "email" | "number";
    required?: boolean;
    disableAutocomplete?: boolean;
    maxLength?: number;
    isValid?: boolean;
    isInvalid?: boolean;
    description?: string;
    disabled?: boolean;
    placeholder?: string;
    showInputFieldInReadOnly?: boolean;
    defaultValueDash?: boolean;
    min?: number;
    max?: number;
    onKeyDown?: (event: any) => void;
    autofocus?: boolean
    onFocusEdit?: boolean;
    style?: CSSProperties;
    checklistTableView?: boolean;
    title?: string;
}

const CustomTextInput = (
    {
        isEditMode = true,
        name,
        value,
        onChange,
        type = 'text',
        required,
        disableAutocomplete,
        isValid,
        testId,
        description = "",
        maxLength,
        isInvalid,
        disabled,
        placeholder,
        showInputFieldInReadOnly,
        defaultValueDash = true,
        min,
        max,
        onKeyDown,
        autofocus,
        onFocusEdit = false,
        style,
        checklistTableView,
        title,
    }: ICustomTextInput & ITestAble) => {
    const ref = useRef<any>();
    const [forceEdit, setForceEdit] = useState(false);

    useEffect(() => {
        if (forceEdit) {
            ref.current.focus()
            if (value && typeof value === "string") {
                ref.current.setSelectionRange(value.length, value.length)
            }
        }
    }, [forceEdit])

    useEffect(() => {
        if (autofocus)
            ref.current?.focus()
    }, [])

    return <When
        condition={forceEdit || isEditMode}
        otherwise={
            showInputFieldInReadOnly ? (
                <Form.Control
                    className={checklistTableView ? "checklistTableFields" : ""}
                    style={style}
                    disabled={false}
                    data-test-id={testId}
                    value={value ? value : defaultValueDash ? "-" : undefined}
                    onChange={() => NaN}
                    readOnly
                    title={title}
                />
            ) : <span onClick={() => onFocusEdit && setForceEdit(true)}
                      data-test-id={testId} title={title}>
                {value ? value : defaultValueDash ? "-" : undefined}
            </span>
        }>
        <Form.Control
            className={checklistTableView ? "checklistTableFields" : ""}
            style={style}
            ref={ref}
            placeholder={placeholder}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            required={required}
            isInvalid={isInvalid}
            isValid={isValid}
            disabled={disabled}
            autoComplete={disableAutocomplete ? "off" : undefined}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            onBlur={() => setForceEdit(false)}
            max={max}
            min={min}
            autoFocus={autofocus}
            title={title}
        />
        <When condition={!!description?.length}>
            <Form.Text muted>
                {description}
            </Form.Text>
        </When>
    </When>
};

export default CustomTextInput;