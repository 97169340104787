import { useTranslation } from "react-i18next";
import Select from "react-select";

interface ICustomMultiSelect {
    isEditMode: boolean;
    name: string;
    options: {value: string, label: string}[];
    selected: {value: string, label: string}[];
    onChange: (e: any) => void;
    required?: boolean;
    disabled?: boolean;
}

const CustomMultiSelect = ({
    isEditMode,
    name,
    options,
    selected,
    onChange,
    disabled = false,
}: ICustomMultiSelect) => {

    const {t} = useTranslation();

    return <>
        {isEditMode ? (
            <Select
                isMulti
                isDisabled={disabled}
                name={name}
                options={options}
                defaultValue={selected}
                noOptionsMessage={() => t('CustomMultiSelect.NO_OPTIONS_1') }
                onChange={onChange}
            />
        ) : (
            <ul>
                {(selected ?? []).map((item, index) => (
                    <li key={index}>{item.label}</li>
                ))}
            </ul>
        )}
    </>
};

export default CustomMultiSelect;