import React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteCompanyUser } from "../../api/CompanyApi";
import ModalForm from "../../components/FormComponents/ModalForm";
import { ICompanyUserExtended } from "../../core/interfaces/company";


const RemoveCompanyUserModal: React.FC<{ show: boolean, hide: () => void, currentUser: ICompanyUserExtended | null }> = ({
                                                                                                                    show,
                                                                                                                    hide,
                                                                                                                    currentUser
                                                                                                                }) => {

    const {t} = useTranslation();
    const [remove, isRemoving] = useDeleteCompanyUser(currentUser?.userId ?? '');
    if(currentUser == null) return <></>

    return (
        <ModalForm show={show} hide={hide} title={t('RemoveCompanyUserModal.DELETE_USER_1')} isEdited={() => false} submit={() => remove().then(_ => hide())} submitText={t('RemoveCompanyUserModal.YES_1')}
                   isLoading={isRemoving}>
            <p>{t('RemoveCompanyUserModal.DELETE_USER_CONFIRMATION_1')}</p>
        </ModalForm>
    )
}

export default RemoveCompanyUserModal
