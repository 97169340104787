import { useQueryClient } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import i18n from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ImageVersion } from "../core/enums/enums";
import { downloadFileResponse, fileResponse } from "../core/helpers/fileHelper";
import { toastError, toastSuccess } from "../core/helpers/toastHelper";
import { IActionPlan } from "../core/interfaces/actionPlan";
import { ICustomQuery, ICustomQueryKeys } from "../core/interfaces/common";
import { IDataRow } from "../core/interfaces/document";
import { AppQueryKey, GetQueryKey, useInvalidateQuery } from "../core/queryKeys";
import { useDelete, useGet, usePost, usePut } from "../hooks/useCustomQuery";
import { useAppDispatch } from "../store/hooks";
import { addToast } from "../store/slices/uiSlice";
import AxiosClient from "./api";
import {
    URL_ACTION_PLAN_DELETE,
    URL_ACTION_PLAN_DOWNLOAD_FILE,
    URL_ACTION_PLAN_DOWNLOAD_IMAGE,
    URL_CREATE_ACTION_FROM_INCIDENT,
    URL_CREATE_ACTION_FROM_STANDALONE,
    URL_GET_ACTION_PLAN,
    URL_GET_ACTION_PLAN_BY_ID,
    URL_SET_ACTION_COMPLETED,
    URL_SET_ACTION_PLAN_ON_GOING,
    URL_UPDATE_ACTIONPLAN_DATAROW
} from "./endpoints/endpoints";

export const useGetActionPlans = (): [IActionPlan[], boolean, boolean] => {
    const {cId} = useParams()
    const queryKeys: ICustomQueryKeys = {root: "actionPlans", cId};
    const query: ICustomQuery = {
        url: URL_GET_ACTION_PLAN(cId!),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data,
        isFetched
    } = useGet<IActionPlan[]>(query);

    return [(data?.data as unknown as IActionPlan[] ?? []), isFetching, isFetched]
}

export const useGetActionPlanById = (id: string): [IActionPlan, boolean, boolean] => {
    const {cId} = useParams()
    const queryKeys: ICustomQueryKeys = {root: "actionPlan", cId, extraKeys: [id]};
    const query: ICustomQuery = {
        url: URL_GET_ACTION_PLAN_BY_ID(cId!, id),
        queryKeys: queryKeys
    };

    const {
        isFetching,
        data,
        isFetched
    } = useGet<IActionPlan>(query);

    return [(data?.data as unknown as IActionPlan), isFetching, isFetched]
}

export const useSetActionPlanAsOngoing = (actionPlanId: string): [() => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "actionPlan", cId, extraKeys: [actionPlanId!]};

    const usePostMutation = usePut<{}>(
        URL_SET_ACTION_PLAN_ON_GOING(cId!, actionPlanId!),
        queryKeys,
        true
    );

    const request = () => usePostMutation.mutateAsync({} as any).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('ActionPlanApi.ACTION_ITEM_1'), i18n.t('ActionPlanApi.ACTION_ITEM_INITIATED_1'))));
            await queryClient.refetchQueries(["actionPlans", cId]);
            await queryClient.refetchQueries(["actionPlan", cId, actionPlanId!]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}


export const useUpdateOngoingActionPlan = (actionPlanId: string): [(action: string, isCompleted: boolean, complementaryRows: IDataRow[], formDataRows : IDataRow[]) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "actionPlan", cId, extraKeys: [actionPlanId!]};

    const usePostMutation = usePut<{ action: string, isCompleted: boolean, complementaryRows: IDataRow[], formDataRows: IDataRow[] }>(
        URL_SET_ACTION_COMPLETED(cId!, actionPlanId!),
        queryKeys,
        true
    );

    const request = (action: string, isCompleted: boolean, complementaryRows: IDataRow[], formDataRows: IDataRow[]) => usePostMutation.mutateAsync({
        action,
        isCompleted,
        complementaryRows,
        formDataRows
    }).then(async (response) => {
        if (response.success) {
            if (isCompleted) {
                dispatch(addToast(toastSuccess(i18n.t('ActionPlanApi.ACTION_ITEM_COMPLETE_1'), i18n.t('ActionPlanApi.ACTION_ITEM_COMPLETE_2'))));
            } else {
                dispatch(addToast(toastSuccess(i18n.t('ActionPlanApi.ACTION_ITEM_UPDATED_1'), i18n.t('ActionPlanApi.CHANGES_SAVED_1'))));
            }
            await queryClient.refetchQueries(["actionPlans", cId]);
            await queryClient.refetchQueries(["actionPlan", cId, actionPlanId!]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useCreateActionPlanFromIncident = (): [(rows: IDataRow[], type: string) => Promise<boolean>, boolean] => {
    const { cId } = useParams();

    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = { root: "actionPlans", cId };


    const usePostMutation = usePost<{ rows: IDataRow[], type: string }>(

        URL_CREATE_ACTION_FROM_INCIDENT(cId!),
        queryKeys,
        true
    );

    const request = (rows: IDataRow[], type: string) => usePostMutation.mutateAsync({
        rows,
        type: type
    }).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('ActionPlanApi.ACTION_CREATED_1'), i18n.t('ActionPlanApi.ACTION_PLAN_SENT_1'))));
            await queryClient.refetchQueries(["actionPlans", cId]);
        }
        return !!response.success;
    });

    return [request, usePostMutation.isLoading];

}

export const useCreateActionPlanFromStandalone = (): [(rows: IDataRow[]) => Promise<boolean>, boolean] => {
    const dispatch = useAppDispatch();
    const {cId} = useParams();
    const queryKeys = GetQueryKey(AppQueryKey.ACTION_PLANS);

    const usePostMutation = usePost<{ rows: IDataRow[] }>(
        URL_CREATE_ACTION_FROM_STANDALONE(cId!),
        queryKeys,
        true
    );

    const request = (rows: IDataRow[]) => usePostMutation.mutateAsync({rows}).then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('ActionPlanApi.ACTION_PLAN_CREATED_1'), i18n.t('ActionPlanApi.ACTION_PLAN_CREATED_2'))));
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}

export const useDeleteActionPlan = (id: string): [() => Promise<boolean>, boolean] => {
    const { cId } = useParams();
    // const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const queryKeys = GetQueryKey(AppQueryKey.ACTION_PLANS);
    const useDeleteMutation = useDelete<void>(
        URL_ACTION_PLAN_DELETE(cId!, id),
        queryKeys,
        true
    );

    const invalidateActionPlans = useInvalidateQuery(AppQueryKey.ACTION_PLANS);

    const request = () => useDeleteMutation.mutateAsync().then(async (response) => {
        if (response.success) {
            dispatch(addToast(toastSuccess(i18n.t('ActionPlanApi.ACTION_PLAN_DELETED_1'), i18n.t('ActionPlanApi.ACTION_PLAN_DELETED_2'))));
            await invalidateActionPlans();
        }
        return !!response.success;
    });
    return [request, useDeleteMutation.isLoading];
}

export const useDownloadActionPlanDataRowImageAttachment = (
    actionPlanId: string,
    isFromReportForm: Boolean,
): [() => void, boolean] => {

    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState(false);
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = () => {
        setIsDownloading(true);

        var imageVersion = isFromReportForm ? ImageVersion.ACTIONPLANFORM : ImageVersion.ACTIONPLANCOMPLEMENTARYFORM;
        AxiosClient.get(URL_ACTION_PLAN_DOWNLOAD_IMAGE(actionPlanId, imageVersion), config).then((response) => {
            downloadFileResponse(fileResponse(response))
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('ActionPlanApi.COULD_NOT_FETCH_IMAGE_1'), i18n.t('ActionPlanApi.ERROR_RETRIEVING_IMAGE_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}

export const useDownloadActionPlanDataRowFile = (
    dataRowFileId: string,
    actionPlanId: string,
): [() => void, boolean] => {
    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState(false);

    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };

    const download = () => {
        setIsDownloading(true);
        AxiosClient.get(URL_ACTION_PLAN_DOWNLOAD_FILE(actionPlanId, dataRowFileId), config).then((response) => {
            downloadFileResponse(fileResponse(response));
        }).catch(() => {
            dispatch(addToast(toastError(i18n.t('ActionPlanApi.COULD_NOT_FETCH_THE_FILE_1'), i18n.t('ActionPlanApi.ERROR_FETCHING_FILE_1'))));
        }).finally(() => setIsDownloading(false));
    }
    return [download, isDownloading];
}

export const useUpdateActionPlanDataRow = (actionPlanId: string): [(dataRow: IDataRow, createNew?: boolean) => Promise<boolean>, boolean] => {
    const {cId} = useParams();
    const queryClient = useQueryClient();
    const queryKeys: ICustomQueryKeys = {root: "actionPlan", cId, extraKeys: [actionPlanId!]};

    const usePostMutation = usePut<{ dataRow: IDataRow, createNew?: boolean }>(
        URL_UPDATE_ACTIONPLAN_DATAROW(cId!, actionPlanId!),
        queryKeys,
        true
    );

    const request = (dataRow: IDataRow, createNew?: boolean) => usePostMutation.mutateAsync({
        dataRow,
        createNew
    }).then(async (response) => {
        if (response.success) {
            await queryClient.invalidateQueries(["actionPlans", cId]);
            await queryClient.invalidateQueries(["actionPlan", cId, actionPlanId!]);
        }
        return !!response.success;
    });
    return [request, usePostMutation.isLoading];
}
