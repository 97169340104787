import { FormEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUploadDocument } from "../../../api/DocumentApi";
import { Icon } from "../../../assets/Icons/IconClassNames";
import CustomDropdown, { DropdownToggleType } from "../../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import When from "../../../components/When";
import { TemplateType } from "../../../core/enums/enums";
import { toastError } from "../../../core/helpers/toastHelper";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/slices/uiSlice";
import CreateFromTemplateModal from "../../DocumentDirectory/Components/CreateFromTemplateModal";
import CreateNewModal from "../../DocumentDirectory/CreateNewModal/CreateNewModal";
import NewDocumentModal from "../../DocumentDirectory/CreateNewModal/NewDocumentModal";
import { getBlockedFileTypes } from "../util";

export const FileUpload = ({isEditMode, companyUserId}: { isEditMode: boolean, companyUserId: string }) => {
    const dispatch = useAppDispatch();

    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [upload, isUploading] = useUploadDocument();
    const [showTemplates, setShowTemplates] = useState(false);
    const [showCreateDocumentModal, setShowCreateDocumentModal] = useState(false);
    const handleNewFile = async (e: FormEvent<HTMLInputElement>) => {
        const file = e?.currentTarget?.files?.item(0);
        if (file) {
            if (getBlockedFileTypes.includes(file.type)) return dispatch(addToast(toastError(t('FileUpload.BLOCKED_FORMAT_1'), t('FileUpload.BLOCKED_FORMAT_ERROR_1'))));

            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", file.name);
            formData.append("CompanyUserId", companyUserId);
            await upload(formData);
        }
    }


    return (<>
            <When condition={isEditMode && !isUploading}>
                <div style={{position: 'absolute'}}>
                    <input onInputCapture={handleNewFile} ref={inputRef} type={"file"} id={"user-file"} multiple hidden
                           accept=""
                           onClick={(e: any) => (e.target.value = null)} // Reset value to allow same file to be uploaded again
                    />

                    <CustomDropdown toggleType={DropdownToggleType.DOTS} horizontal={false} iconSize={"30px"}>
                        <CustomDropdownItem
                            icon={Icon.COPY}
                            text={t('FileUpload.TEMPLATE_CREATION_1')}
                            onClick={() => setShowTemplates(true)}
                        />

                        <CustomDropdownItem
                            icon={Icon.PLUS}
                            text={t('FileUpload.NEW_DOCUMENT_1')}
                            onClick={() => setShowCreateDocumentModal(true)}
                        />

                        <CustomDropdownItem
                            icon={Icon.UPLOAD}
                            text={t('FileUpload.UPLOAD_FILE_1')}
                            onClick={() => inputRef.current?.click()}
                        />

                    </CustomDropdown>
                </div>
                <CreateFromTemplateModal
                    visible={showTemplates}
                    setVisible={setShowTemplates}
                    templateType={TemplateType.DOCUMENT}
                    companyUserId={companyUserId}
                />
            </When>
            <CreateNewModal
                showModal={showCreateDocumentModal}
                closeModal={() => setShowCreateDocumentModal(false)}
            >
                <NewDocumentModal
                    folderId={null}
                    closeModal={() => setShowCreateDocumentModal(false)}
                    companyUserId={companyUserId}
                />
            </CreateNewModal>
        </>
    )
}
