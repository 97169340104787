import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetActionPlanById, useSetActionPlanAsOngoing, useUpdateOngoingActionPlan } from "../../api/ActionPlanApi";
import FormContainer from "../../components/FormContainer/FormContainer";
import LoadingSpinner from "../../components/LoadingSpinner";
import SpinnerButton from "../../components/SpinnerButton";
import When from "../../components/When";
import { ActionPlanStatus, ActionPlanType, CompanyRole, DataRowSubType, DataRowType } from "../../core/enums/enums";
import { Internal_Ids, NewDescriptionField } from "../../core/helpers/dataRowHelper";
import { IActionPlan } from "../../core/interfaces/actionPlan";
import { IDataRow, IDataRowMultiChoiceField, IDataRowTextField } from "../../core/interfaces/document";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useGetUser } from "../../hooks/useAuth";
import GeneralizedDataRow from "../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedDataRow";
import GeneralizedFormHeader from "../Document/DocumentModules/ChecklistModule/ChecklistForm/GeneralizedFormHeader";
import ActionPlanDescription from "./Components/ActionPlanDescription";
import DataRow from "./Components/DataRow";

function ActionPart(props: {
    actionPlan: IActionPlan,
    canEdit: boolean,
    complementaryDataRows: IDataRow[],
    formDataRows: IDataRow[],
    canComplete: () => boolean,
    setShow: () => void
}) {
    const { t } = useTranslation();
    const [setAsOngoing, isSettingAsOngoing] = useSetActionPlanAsOngoing(props.actionPlan.id);
    const [setAsCompleted] = useUpdateOngoingActionPlan(props.actionPlan.id);
    const [action] = useState(props.actionPlan.action);

    return (
        <When condition={props.canEdit}>
            <When condition={props.actionPlan.status === ActionPlanStatus.CREATED}>
                <SpinnerButton text={t('ActionPlanModal.START_1')} isLoading={isSettingAsOngoing}
                    onClick={() => setAsOngoing()}></SpinnerButton>
            </When>
            <When condition={props.actionPlan.status === ActionPlanStatus.ONGOING}>
                <Button type={"submit"}
                    onClick={() => props.canComplete() && setAsCompleted(action, true, props.complementaryDataRows, props.formDataRows).then((response) => response && props.setShow())}>{t('ActionPlanModal.READY_1')}</Button>

                <Button
                    onClick={() => setAsCompleted(action, false, props.complementaryDataRows, props.formDataRows).then((response) => response && props.setShow())}>{t('ActionPlanModal.SAVE_1')}</Button>
            </When>
        </When>);
}

function PlanModal(props: { show: boolean, setShow: () => void, id: string, callbackfn: (row: IDataRow) => JSX.Element }) {
    const { t } = useTranslation();
    const { cId } = useParams();
    const [actionPlan, isFetching, isFetched] = useGetActionPlanById(props.id);
    const user = useGetUser();
    const [checkCompanyRole] = useAccessCheck();
    const [validated, setValidated] = useState(false);
    const [descriptionDataRow, setDescriptionDataRow] = useState<IDataRow>(NewDescriptionField(DataRowSubType.INCIDENT_HEAD_DESCRIPTION));
    const [canEdit, setCanEdit] = useState(false);

    const ref = useRef<any>(null);
    const [complementaryDataRows, setComplementaryDataRows] = useState<IDataRow[]>([]);
    const [formDataRows, setFormDataRows] = useState<IDataRow[]>([]);

    const handleUpdates = (dataRow: IDataRow) => {
        setComplementaryDataRows(complementaryDataRows.map(row => dataRow.subType === row.subType ? dataRow : row))
    }

    const handleUpdatesForm = (dataRow: IDataRow) => {
        if (dataRow.checklistFormDataRowTypeSymbol === DataRowType.MULTI_CHOICE_FIELD && (dataRow as IDataRowMultiChoiceField).multiChoiceValue == null)
            return;

        let rowRef = formDataRows;
        if (dataRow.subType === DataRowSubType.INCIDENT_TYPE) {
            return;
        }

        if (dataRow.id === Internal_Ids.EXTERNAL_USER_TOGGLE) {
            rowRef = rowRef.map(v => {
                if (v.id === Internal_Ids.EXTERNAL_USER_TEXT) {
                    v.isClosed = (dataRow as IDataRowMultiChoiceField).multiChoiceValue !== t('ActionPlanModal.AFFIRMATION_1');
                    (v as IDataRowTextField).textValue = "";
                }
                return v;
            })
        }

        setFormDataRows(rowRef.map((row) => (row.id === dataRow.id) ? dataRow : row));
    }


    useEffect(() => {
        setTimeout(() => {
            if (actionPlan !== undefined && user !== undefined) {
                setCanEdit(user.userId === actionPlan?.actionUser.id || checkCompanyRole(CompanyRole.ADMIN));
                setComplementaryDataRows(actionPlan.complementaryForm?.documentModuleChecklistFormDataRows ?? []);
                setFormDataRows(actionPlan.form?.documentModuleChecklistFormDataRows.filter((dr) => dr.subType !== DataRowSubType.INCIDENT_HEAD_DESCRIPTION) ?? []);

                const result = actionPlan?.form.documentModuleChecklistFormDataRows.filter((dr) => dr.subType === DataRowSubType.INCIDENT_HEAD_DESCRIPTION)[0];
                if (result)
                    setDescriptionDataRow({ ...result });
                else
                    setDescriptionDataRow(NewDescriptionField(DataRowSubType.INCIDENT_HEAD_DESCRIPTION));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionPlan])


    const handleSubmit = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        e.preventDefault();
        e.stopPropagation();
    }

    const canComplete = () => {
        const faulty = complementaryDataRows.filter(v => {
            if (v.checklistFormDataRowTypeSymbol === DataRowType.MULTI_CHOICE_FIELD)
                return (v as IDataRowMultiChoiceField).multiChoiceValue == null;

            if (v.checklistFormDataRowTypeSymbol === DataRowType.TEXT_FIELD)
                return (v as IDataRowTextField).textValue == null;

            return false;
        })

        if (!!faulty.length) {
            setValidated(true);
            ref?.current?.click();
            return false;
        }

        return true;
    }


    // if (actionPlan === undefined || JSON.stringify(actionPlan) === "{}") return null; //todo check status instead.
    return <Modal {...props} size={"xl"} centered onHide={() => props.setShow()}>
        {isFetching && !isFetched ? (<Modal.Body><LoadingSpinner /></Modal.Body>) : (
            <>
                <Modal.Body>
                    <FormContainer>
                        <When condition={ActionPlanType.CHECKLIST === actionPlan.type}>
                            <div className={"d-flex justify-content-end pe-3"}>
                                <span>{t('ActionPlanModal.CREATED_FROM_1')}</span>
                                <span className={"ps-2"}>
                                    <a href={`/c/${cId}/documents/${actionPlan.relatedDocument?.id}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        {actionPlan.relatedDocument?.documentName}
                                    </a>
                                </span>
                            </div>
                        </When>
                        <When condition={actionPlan.type !== ActionPlanType.CHECKLIST}>
                            <b><GeneralizedFormHeader isExpanded={false} setExpanded={() => NaN}
                                title={actionPlan.serialNumber != null ? t('ActionPlanModal.SERIAL_NUMBER_1') + actionPlan.serialNumber : ""}
                                description={" "} isHeaderLocked={true} /></b>
                        </When>
                        <b><GeneralizedFormHeader isExpanded={false} setExpanded={() => NaN} title={t('ActionPlanModal.PREVIOUS_DATA_1')}
                            description={" "} isHeaderLocked={true} /></b>
                        <ActionPlanDescription
                            key={descriptionDataRow.id}
                            isNew={false}
                            dataRow={descriptionDataRow}
                            setDataRow={setDescriptionDataRow}
                        />
                        <GeneralizedDataRow
                            dataRows={formDataRows}
                            handleUpdateDataRow={handleUpdatesForm}
                            isActiveMode={false}
                            isEditMode={false}
                            isFromActionPlan={true}
                            actionPlanId={actionPlan.id}
                            isFromReportForm={true}
                            isAnswerMode={true}
                            handleRemoveDataRow={() => NaN}
                            handleCopyDataRow={() => NaN}
                        />
                        <When condition={actionPlan.status !== ActionPlanStatus.CREATED}>
                            <hr />
                            <b><GeneralizedFormHeader isExpanded={false} setExpanded={() => NaN}
                                                      title={t('ActionPlanModal.INFORMATION_ABOUT_MEASURES_1')} description={" "}
                                                      isHeaderLocked={true}/></b>
                            <Form validated={validated} noValidate onSubmit={(e) => handleSubmit(e)}>
                                <GeneralizedDataRow dataRows={complementaryDataRows}
                                    isFromActionPlan={true}
                                    actionPlanId={actionPlan.id}
                                    forceHeaderDisabled={true}
                                    handleUpdateDataRow={handleUpdates}
                                    isActiveMode={canEdit && (actionPlan.status === ActionPlanStatus.ONGOING)}
                                    isEditMode={false}
                                    isAnswerMode={canEdit && (actionPlan.status === ActionPlanStatus.ONGOING)}
                                    handleRemoveDataRow={() => NaN} handleCopyDataRow={() => NaN}
                                    requiredSubType={[DataRowSubType.NONE, DataRowSubType.INCIDENT_INSTANT_ACTION, DataRowSubType.INCIDENT_LONGTERM_ACTION, DataRowSubType.INCIDENT_FOLLOW_UP, DataRowSubType.INCIDENT_ROOT_CAUSE, DataRowSubType.INCIDENT_MEDICAL_ASSISTANCE, DataRowSubType.INCIDENT_SICKDAYS]} />
                                <button type={"submit"} hidden ref={ref} />
                            </Form>
                        </When>
                    </FormContainer>
                </Modal.Body>
                <Modal.Footer>
                    <ActionPart complementaryDataRows={complementaryDataRows}
                        formDataRows={[...formDataRows, descriptionDataRow]}
                        actionPlan={actionPlan}
                        canEdit={canEdit}
                        canComplete={canComplete}
                        setShow={props.setShow} />
                </Modal.Footer>
            </>
        )}
    </Modal>
}

interface IActionPlanModal {
    actionPlan: IActionPlan | null;
    show: boolean;
    setShow: () => void;
}

const ActionPlanModal = (props: IActionPlanModal) => {

    if (props.actionPlan == null)
        return <></>

    return <PlanModal show={props.show} setShow={() => props.setShow()} id={props.actionPlan?.id!} callbackfn={row => <DataRow dataRow={row} />} />
}

export default ActionPlanModal;