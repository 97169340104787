import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateCompanyUserRole } from "../../api/CompanyApi";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import When from "../../components/When";
import { CompanyRole, Role } from "../../core/enums/enums";
import { ICompanyUserExtended } from "../../core/interfaces/company";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useGetUser } from "../../hooks/useAuth";


const EditCompanyUserRole: React.FC<{ show: boolean, hide: () => void, currentUser: ICompanyUserExtended | null }> = (
    {
        show,
        hide,
        currentUser
    }) => {

    const {t} = useTranslation();
    const [checkCompanyRole, checkAccess] = useAccessCheck();
    const user = useGetUser();
    const [update, isUpdating] = useUpdateCompanyUserRole(currentUser?.userId ?? '');
    const [updatedObject, setUpdatedObject] = useState({...currentUser});
    const commonProps = {
        isEditMode: true,
        object: updatedObject,
        setValue: setUpdatedObject
    }

    useEffect(() => {
        setUpdatedObject({...currentUser})
    }, [currentUser])


    if (currentUser == null) return <></>
    const isCurrentUserLoggedInUser = !checkAccess(Role.SUPERADMIN) && currentUser.userId === user.userId;
    return (
        <ModalForm show={show} hide={hide} title={t('EditCompanyUserRole.EDIT_USER_1')} isEdited={() => false}
                   submit={() => updatedObject.roleSymbol && update(updatedObject.roleSymbol).then(_ => hide())}
                   submitText={t('EditCompanyUserRole.SAVE_1')}
                   disableSubmit={isCurrentUserLoggedInUser}
                   isLoading={isUpdating}>
            <FormBase row={false} controlId={'formFirstName'} title={t('EditCompanyUserRole.FIRST_NAME_1')}
                      className={'pb-2'}>
                <GenericInput name={'name'} {...commonProps} disabled/>
            </FormBase>
            <FormBase row={false} controlId={'formEmail'} title={t('EditCompanyUserRole.E_MAIL_1')}
                      className={'pb-2'}>
                <GenericInput type={'email'} name={'email'} {...commonProps} disabled/>
            </FormBase>
            <FormBase row={false} controlId={'formRole'} title={t('EditCompanyUserRole.ROLE_1')}
                      className={'pb-2'}>
                <GenericInput type={'select'} name={'roleSymbol'} {...commonProps} disabled={isCurrentUserLoggedInUser}>
                    <option value={CompanyRole.READER}>{t('EditCompanyUserRole.READER_1')}</option>
                    <option value={CompanyRole.EDITOR} disabled={!checkCompanyRole(CompanyRole.ADMIN)}>{t('EditCompanyUserRole.EDITOR_1')}</option>
                    <option value={CompanyRole.ADMIN} disabled={!checkCompanyRole(CompanyRole.ADMIN)}>{t('EditCompanyUserRole.ADMIN_1')}</option>
                </GenericInput>
                <When condition={isCurrentUserLoggedInUser}>
                    <span style={{color: "#842029"}}>{t('EditCompanyUserRole.DISCLAIMER_1')}</span>
                </When>
            </FormBase>
        </ModalForm>
    )
}

export default EditCompanyUserRole
