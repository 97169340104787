import _ from "lodash";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useAnswerChecklist } from "../../../../../api/DocumentModuleChecklistApi";
import getIcon, { Icon } from "../../../../../assets/Icons/IconClassNames";
import SimpleConfirmModal from "../../../../../components/ModalComponents/SimpleConfirmModal";
import SimpleModal from "../../../../../components/ModalComponents/SimpleModal";
import SpinnerButton from "../../../../../components/SpinnerButton";
import { DataRowType } from "../../../../../core/enums/enums";
import { useDocumentModulesContext } from "../../DocumentModules";

interface IAnswerChecklistContainer {
    showAnswerChecklistModal: boolean;
    closeAnswerChecklist: () => void;
    children: JSX.Element;
}

const AnswerChecklistContainer = (
    {
        showAnswerChecklistModal,
        closeAnswerChecklist,
        children
    }: IAnswerChecklistContainer) => {

    const {t} = useTranslation();
    const [showCancelChecklistModal, setShowCancelChecklistModal] = useState(false);

    const {activeModule, isNoChangesActiveModule, isSaveDisabled} = useDocumentModulesContext()

    const [activeChecklist] = useState(activeModule?.documentModuleChecklist!);
    const [answerChecklist] = useAnswerChecklist(activeModule?.id ?? '');
    const handleAnswerChecklist = async () => {
        const requestObj = _.cloneDeep(activeModule)!;
        requestObj.documentModuleChecklist?.documentModuleChecklistForms.forEach(v => {
            if(v.sendToActionPlan) {
                v.documentModuleChecklistFormDataRows.forEach(v => {
                    if(v.checklistFormDataRowTypeSymbol == DataRowType.ACTION_PLAN_FIELD && v.isNew) {
                        delete v.id;
                    }
                })
            }
        })

        await answerChecklist(requestObj).then((response) => {
            if (response.success) {
                closeAnswerChecklist();
            }
        });
    }
    const onClickCancel = () => closeAnswerChecklist()
    return <>
        <SimpleModal
            fullscreen
            show={showAnswerChecklistModal}
            size={"xl"}
            header={<><i className={`${getIcon(Icon.CHECK_CIRCLE)} pe-2`}/>{activeChecklist?.header ?? ""}</>}
            centered={false}
            body={children}
            footer={
                <>
                    <Button
                        variant="secondary"
                        onClick={isNoChangesActiveModule ? () => setShowCancelChecklistModal(true) : onClickCancel}>{t('AnswerChecklistContainer.CANCEL_2')}
                    </Button>
                    <SpinnerButton
                        text={t('AnswerChecklistContainer.SAVE_1')}
                        isLoading={false}
                        disabled={isSaveDisabled}
                        onClick={handleAnswerChecklist}
                    />
                </>}
        />
        <SimpleConfirmModal
            show={showCancelChecklistModal}
            header={<>{t('AnswerChecklistContainer.CANCEL_ACTIVE_CHECKLIST_1')}</>}
            text={<>{t('AnswerChecklistContainer.CANCEL_CONFIRMATION_1')}<i>{activeChecklist?.header}</i>?
                <br/>{t('AnswerChecklistContainer.CHANGES_NOT_SAVED_1')}</>}
            onClickOKText={t('AnswerChecklistContainer.CANCEL_1')}
            onClickOK={onClickCancel}
            onClickCancelText={t('AnswerChecklistContainer.GO_BACK_1')}
            onClickCancel={() => setShowCancelChecklistModal(false)}
        />
    </>
};

export default AnswerChecklistContainer;
