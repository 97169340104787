import { NodeModel } from "@minoru/react-dnd-treeview";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    useCopyTemplate,
    useDeleteTemplate,
    usePublishAllTemplatesByFolderId,
    useRenameTemplateNode
} from "../../api/TemplateApi";
import getIcon, { Icon, IconSize } from "../../assets/Icons/IconClassNames";
import ClickableIconButton from "../../components/ClickableIconButton/ClickableIconButton";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import SimpleConfirmModal from "../../components/ModalComponents/SimpleConfirmModal";
import When from "../../components/When";
import { NodeType, PublishStatusType, TemplateType } from "../../core/enums/enums";
import { IRenameTemplateNodeRequest, ITemplateNodeData } from "../../core/interfaces/template";

interface ITableRow {
    node: NodeModel<ITemplateNodeData>;
    onClickEditName: () => void;
    onClickItem: (item: NodeModel<ITemplateNodeData>) => void;
    onClickDeleteItem: (item: NodeModel<ITemplateNodeData>) => void;
    onClickPublishAll: (node: NodeModel<ITemplateNodeData>) => void;
    onClickCopyItem: (item: NodeModel<ITemplateNodeData>) => void;
}

const TableRow = (
    {
        node,
        onClickEditName,
        onClickItem,
        onClickDeleteItem,
        onClickPublishAll,
        onClickCopyItem,
    }: ITableRow) => {

    const {t} = useTranslation();

    return (
        <tr>
            <td style={{ cursor: "pointer" }} onClick={() => onClickItem(node)}
                className={NodeType.DOCUMENT === node.data?.nodeType && PublishStatusType.APPROVED !== node.data?.publishStatus ? 'unpublishedItemColor' : ''}>
                <When
                    condition={NodeType.FOLDER === node.data?.nodeType}
                    then={<span className={getIcon(Icon.FOLDER)} style={{ paddingRight: 4 }} />}
                    otherwise={<span className={getIcon(Icon.FILE_LINES)} style={{ paddingRight: 4 }} />}
                />
                {node.text}
            </td>
            <td style={{ textAlign: "right" }}
                className={NodeType.DOCUMENT === node.data?.nodeType && PublishStatusType.APPROVED !== node.data?.publishStatus ? 'unpublishedItemColor' : ''}>
                <CustomDropdown toggleType={DropdownToggleType.DOTS}>
                    <CustomDropdownItem icon={Icon.EDIT} text={t('TemplateFolderContent.CHANGE_NAME_1')} onClick={() => onClickEditName()} />
                    <When condition={NodeType.FOLDER !== node.data?.nodeType}>
                        <CustomDropdownItem icon={Icon.COPY} text={t('TemplateFolderContent.COPY_1')} onClick={() => onClickCopyItem(node)} />
                    </When>
                    <When condition={NodeType.FOLDER === node.data?.nodeType}>
                        <CustomDropdownItem icon={Icon.EYE} text={t('TemplateFolderContent.PUBLISH_ALL_1')}
                            onClick={() => onClickPublishAll(node)} />
                    </When>
                    <CustomDropdownItem icon={Icon.REMOVE} text={t('TemplateFolderContent.DELETE_1')} onClick={() => onClickDeleteItem(node)} />
                </CustomDropdown>
            </td>
        </tr>
    )
}

interface ITemplateFolderContent {
    selectedNode: NodeModel<ITemplateNodeData> | null;
    content: NodeModel<ITemplateNodeData>[];
    onClick: (node: NodeModel<ITemplateNodeData>) => void;
    onStepBack: () => void;
    search: string;
}

const TemplateFolderContent = (
    {
        selectedNode,
        content,
        onClick,
        onStepBack,
        search,
    }: ITemplateFolderContent) => {

    const {t} = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [nodeToDelete, setNodeToDelete] = useState<NodeModel<ITemplateNodeData> | null>(null);
    const [showPublishAllModal, setShowPublishAllModal] = useState(false);
    const [nodeToPublishAll, setNodeToPublishAll] = useState<NodeModel<ITemplateNodeData> | null>(null);
    const [renameTemplateNode, isRenamingTemplateNode] = useRenameTemplateNode();
    const [deleteNode, isDeletingNode] = useDeleteTemplate(nodeToDelete?.data?.id ?? "", nodeToDelete?.data?.nodeType ?? "");
    const [publishAllTemplates, isPublishingAll] = usePublishAllTemplatesByFolderId(nodeToPublishAll?.data?.id ?? "", nodeToPublishAll?.text ?? "");
    const [copyTemplate, isCopyingTemplate] = useCopyTemplate();
    const initChangeNameObject: IRenameTemplateNodeRequest = {
        id: '',
        name: '',
        templateType: TemplateType.DOCUMENT,
        nodeType: NodeType.DOCUMENT
    };
    const [changeNameObject, setChangeNameObject] = useState<IRenameTemplateNodeRequest>(initChangeNameObject);
    const [showEditNameModal, setShowEditNameModal] = useState(false);

    const changeTemplateName = async () => {
        await renameTemplateNode(changeNameObject).then((response) => {
            if (response.success) {
                setShowEditNameModal(false);
            }
        });
    }
    const handleChangeName = (item: NodeModel<ITemplateNodeData>) => {
        if (!item.data) return;
        const obj: IRenameTemplateNodeRequest = {
            id: item.data.id ?? '',
            name: item.text,
            templateType: TemplateType.DOCUMENT === item.data.templateType ? TemplateType.DOCUMENT : TemplateType.CHECKLIST,
            nodeType: NodeType.FOLDER === item.data.nodeType ? NodeType.FOLDER : NodeType.DOCUMENT,
        };
        setChangeNameObject(obj);
        setShowEditNameModal(true);
    }
    const handleDeleteNode = async () => {
        if (!nodeToDelete || !nodeToDelete.data) return;
        await deleteNode().then((isDeleted) => {
            if (isDeleted) {
                setNodeToDelete(null);
                setShowDeleteModal(false);
            }
        })
    }

    const handleCancelDelete = () => {
        setNodeToDelete(null);
        setShowDeleteModal(false);
    }

    const handleOnDeleteNodeClick = (node: NodeModel<ITemplateNodeData>) => {
        setShowDeleteModal(true);
        setNodeToDelete(node);
    }

    const handleOnPublishAllClick = (node: NodeModel<ITemplateNodeData>) => {
        setShowPublishAllModal(true);
        setNodeToPublishAll(node);
    }

    const handlePublishAll = async () => {
        if (!nodeToPublishAll || !nodeToPublishAll.data) return;
        await publishAllTemplates().then((isPublished) => {
            if (isPublished) {
                setNodeToPublishAll(null);
                setShowPublishAllModal(false);
            }
        });
    }

    const handleCancelPublishAll = () => {
        setNodeToPublishAll(null);
        setShowPublishAllModal(false);
    }

    const handleCopyTemplate = async (node: NodeModel<ITemplateNodeData>) => {
        await copyTemplate({ templateId: node.data!.id });
    }

    return (
        <>
            <Table className={"striped-table"} striped bordered hover>
                <thead>
                <tr>
                    <th>{t('TemplateFolderContent.NAME_1')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{cursor: !!search ? "default" : "pointer"}} onClick={onStepBack}>
                        <ClickableIconButton
                            disabled={!selectedNode || !!search}
                            buttonType={"EDIT"}
                            icon={getIcon(Icon.THREE_DOTS_HORIZONTAL, IconSize.X1)}
                            onClick={onStepBack}
                        />
                    </td>
                    <td></td>
                </tr>
                <When condition={!!content.length} otherwise={<tr>
                    <td>{t('TemplateFolderContent.NO_CONTENT_FOUND_1')}</td>
                    <td></td>
                </tr>}>
                    {content.map((node) => <TableRow
                        key={node.id}
                        node={node}
                        onClickEditName={() => handleChangeName(node)}
                        onClickItem={onClick}
                        onClickDeleteItem={(node) => handleOnDeleteNodeClick(node)}
                        onClickPublishAll={(node) => handleOnPublishAllClick(node)}
                        onClickCopyItem={(node) => handleCopyTemplate(node)}
                    />)}
                </When>
                </tbody>
            </Table>

            {showEditNameModal &&
                <ModalForm
                    show={showEditNameModal}
                    hide={() => setShowEditNameModal(false)}
                    title={t('TemplateFolderContent.CHANGE_NAME_2')}
                    isEdited={() => false}
                    disableSubmit={changeNameObject.name === ''}
                    submit={changeTemplateName}
                    isLoading={isRenamingTemplateNode}
                >
                    <FormBase
                        row={false}
                        controlId={'name'}
                        title={t('TemplateFolderContent.NEW_NAME_1')}
                        className={'pb-2'}>
                        <GenericInput
                            name={'name'}
                            isEditMode={true}
                            object={changeNameObject}
                            setValue={setChangeNameObject}
                            autofocus
                        />
                    </FormBase>
                </ModalForm>
            }

            {showDeleteModal && <SimpleConfirmModal
                show={showDeleteModal}
                header={<>{t('TemplateFolderContent.DELETE_2')}</>}
                text={<>{t('TemplateFolderContent.SURE_DELETE_1')}</>}
                onClickOKText={t('TemplateFolderContent.CONFIRM_DELETE_1')}
                onClickOK={handleDeleteNode}
                onClickCancelText={t('TemplateFolderContent.CANCEL_1')}
                onClickCancel={handleCancelDelete}
            />}

            {showPublishAllModal && <SimpleConfirmModal
                show={showPublishAllModal}
                header={<>{t('TemplateFolderContent.TEMPLATE_PUBLISHING_1')}</>}
                text={<>{t('TemplateFolderContent.PUBLISH_ALL_TEMPLATES')} "{nodeToPublishAll?.text}?"</>}
                onClickOKText={t('TemplateFolderContent.PUBLISH_1')}
                onClickOK={handlePublishAll}
                onClickCancelText={t('TemplateFolderContent.CANCEL_2')}
                onClickCancel={handleCancelPublishAll}
            />}
        </>
    )
};

export default TemplateFolderContent;
