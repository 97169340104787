import { NodeModel } from "@minoru/react-dnd-treeview";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    useCreateNewChecklistFromTemplate,
    useCreateNewDocumentFromTemplate,
    useGetTemplatesByType
} from "../../../api/TemplateApi";
import FormBase from "../../../components/FormComponents/FormBase";
import GenericInput from "../../../components/FormComponents/GenericInput";
import ModalForm from "../../../components/FormComponents/ModalForm";
import When from "../../../components/When";
import { NodeType, TemplateType } from "../../../core/enums/enums";
import { ITemplateNodeData, ITemplateRequest } from "../../../core/interfaces/template";
import styles from "../../Document/Components/Templates/SaveAsTemplate.module.scss";
import TemplatesDirectoryTree from "../../Document/Components/Templates/TemplatesDirectoryTree";

interface ICreateFromTemplateModal {
    visible: boolean;
    setVisible: (state: boolean) => void;
    templateType: TemplateType;
    parentFolderId?: string;
    parentFolderName?: string;
    companyUserId?: string;
}

const CreateFromTemplateModal = (
    {
        visible,
        setVisible,
        templateType,
        parentFolderId,
        parentFolderName,
        companyUserId
    }: ICreateFromTemplateModal) => {

    const initObject: ITemplateRequest = {
        nodeType: null,
        id: '',
        name: '',
        parentFolderId
    }

    const {t} = useTranslation();
    const [templateRequest, setTemplateRequest] = useState<ITemplateRequest>(initObject);
    const [selectedNode, setSelectedNode] = useState<NodeModel<ITemplateNodeData> | null>(null);

    const [createNewDocumentTemplate, isCreatingNewDocumentTemplate] = useCreateNewDocumentFromTemplate(templateRequest.id);
    const [createNewChecklistTemplate, isCreatingNewChecklistTemplate] = useCreateNewChecklistFromTemplate(templateRequest.id);
    const {data: templates, isFetching: isFetchingTemplates} = useGetTemplatesByType(templateType, true);
    const [data, setData] = useState<NodeModel<ITemplateNodeData>[]>([]);
    useEffect(() => {
        if (templates) {
            setData(templates.data.data as NodeModel<ITemplateNodeData>[]);
        }
    }, [templates]);

    const clearAll = () => {
        setTemplateRequest(initObject);
        setSelectedNode(null);
    }

    useEffect(() => {
        if (visible) {
            clearAll();
        }
        // eslint-disable-next-line
    }, [visible]);

    const handleSetSelectedNode = (node: NodeModel<ITemplateNodeData>) => {
        if (selectedNode === node) {
            setSelectedNode(null);
            setTemplateRequest(initObject);
        } else {
            setSelectedNode(node);
            const requestCopy = {...templateRequest};
            requestCopy.id = node.data?.id ?? "";
            requestCopy.name = node.text;
            requestCopy.nodeType = NodeType.FOLDER === node.data!.nodeType ? NodeType.FOLDER : NodeType.DOCUMENT;
            setTemplateRequest(requestCopy);
        }
    }

    const isDisabled = (): boolean => {
        return templateRequest.name === "" || templateRequest.id === "" || templateRequest.nodeType === null;
    }

    const handleCreateFromTemplate = async () => {
        if (isDisabled()) return;

        const tRequest = {...templateRequest, companyUserId: companyUserId}
        if (TemplateType.DOCUMENT === templateType) {
            await createNewDocumentTemplate(tRequest).then((isCreated) => {
                if (isCreated) {
                    clearAll();
                }
            });
        } else if (TemplateType.CHECKLIST === templateType) {
            await createNewChecklistTemplate(tRequest).then((isCreated) => {
                if (isCreated) {
                    clearAll();
                }
            });
        }
    }

    const renderBody = () => (<>
        <Row className={"mb-2"}>
            <Col>{t('CreateFromTemplateModal.SELECT_FOLDER_TEMPLATE_1')}</Col>
        </Row>
        <Row>
            <Col className={styles.templatesDirectoryTreeBorder}>
                <TemplatesDirectoryTree
                    templatesData={data}
                    onClickNode={handleSetSelectedNode}
                    selectedNode={selectedNode}
                />
            </Col>
        </Row>
        {NodeType.DOCUMENT === selectedNode?.data?.nodeType && (
            <Row>
                <Col>
                    <FormBase
                        row={false}
                        controlId={'formFirstName'}
                        title={TemplateType.DOCUMENT === templateType ? t('CreateFromTemplateModal.DOCUMENT_NAME_1') : t('CreateFromTemplateModal.CHECKLIST_NAME_1')}
                        className={'pb-2'}
                    >
                        <GenericInput<ITemplateRequest>
                            name={'name'}
                            isEditMode={true}
                            object={templateRequest}
                            setValue={(v) => setTemplateRequest(v)}
                            autofocus={true}
                        />
                    </FormBase>
                </Col>
            </Row>
        )}
    </>);

    return <ModalForm
        show={visible}
        size={"lg"}
        hide={() => setVisible(false)}
        title={TemplateType.DOCUMENT === templateType ? t('CreateFromTemplateModal.TEMPLATE_CREATION_1') : t('CreateFromTemplateModal.TEMPLATE_CREATION_2')}
        isEdited={() => templateRequest.name !== ""}
        submit={handleCreateFromTemplate}
        isLoading={isCreatingNewDocumentTemplate || isCreatingNewChecklistTemplate || isFetchingTemplates}
        centered={false}
        disableSubmit={isDisabled()}
    >
        {renderBody()}
        <When condition={!!parentFolderName}>
            <p><i>{t('CreateFromTemplateModal.CREATED_IN_PARENT_FOLDER', {parentFolder: parentFolderName})} {(parentFolderName ?? t('CreateFromTemplateModal.IN_FOLDER', {folder: parentFolderName}))+'\\'}</i></p>
        </When>
    </ModalForm>
}
export default CreateFromTemplateModal
