import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    useGetCompanyUsersExtended,
    useGetEmploymentTypes,
    useUpdateCompanyUser,
    useUpdateCompanyUserRole
} from "../../api/CompanyApi";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import HeaderContainer from "../../components/ContainerComponents/HeaderContainer";
import MainContainer from "../../components/ContainerComponents/MainContainer";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import LoadingSpinner from "../../components/LoadingSpinner";
import When from "../../components/When";
import { CompanyRole, EmploymentType, Role } from "../../core/enums/enums";
import { ICompanyInfo, ICompanyUserExtended } from "../../core/interfaces/company";
import useAccessCheck from "../../hooks/useAccessCheck";
import { useGetUser } from "../../hooks/useAuth";
import { useCNavigate } from "../../hooks/useCNavigate";
import FileTable from "./components/FileTable";
import Hr from "./components/Hr";
import { employmentTypeMap, userRoleMap } from "./util";

interface IUserRow {
    children: React.ReactNode[];
    title: string;
}

const UserRow: React.FC<IUserRow> = ({children, title}) => {
    const childCount = children.length / 2;
    return (
        <Hr title={title}>
            {Array(childCount).fill(childCount).map((_, index) => <Row key={title + index}>
                {children.slice(index * childCount, index * childCount + childCount).map((child, index) => <Col
                    key={index + title + index}>{child}</Col>)}
            </Row>)}

        </Hr>
    )
}
const InternalInput = ({
                           controlId,
                           commonProps,
                           title,
                           name,
                           type = "text",
                           disabled = false,
                       }: {
    controlId: string,
    title: string,
    name: string,
    commonProps: any,
    type?: string,
    disabled?: boolean
}) => {
    return (
        <FormBase labelProps={{style: {fontWeight: 500}}} row={false} controlId={controlId} title={title + ":"}
                  className={'pb-2'}>
            <GenericInput type={type} name={name} disabled={disabled} {...commonProps} />
        </FormBase>
    )
}

enum ActiveKeys {
    USER = "user",
    FILES = "files"
}

const User = () => {

    const {t} = useTranslation();
    const user = useGetUser();
    const [users, loading] = useGetCompanyUsersExtended();
    const {cUId} = useParams();
    const [checkAccess, checkRole] = useAccessCheck();
    const navigate = useCNavigate();
    const [updatedObject, setUpdatedObject] = useState<ICompanyUserExtended>();
    const [editMode, setEditMode] = useState(false);
    const [activeKey, setActiveKey] = useState<ActiveKeys>(ActiveKeys.USER);
    const viewMode = !editMode


    const commonProps = {
        isEditMode: !viewMode,
        object: updatedObject,
        setValue: setUpdatedObject,
    }

    const [update, isUpdating] = useUpdateCompanyUser(updatedObject?.userId ?? '');
    const [updateRole, isUpdatingRole] = useUpdateCompanyUserRole(updatedObject?.userId ?? '');
    const [employmentTypes] = useGetEmploymentTypes();

    useEffect(() => {
        if (users.length > 0) {
            const currentUser = users.find(s => s.id === cUId);
            if (currentUser == null) return;
            setUpdatedObject({...currentUser});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    if (!checkAccess(CompanyRole.ADMIN)) {
        navigate('/');
        return null;
    }

    const handleUpdate = async () => {
        if (viewMode) setEditMode(false);
        const updated = _.cloneDeep(updatedObject) as ICompanyInfo;

        const currentUser = users.find(s => s.id === cUId);
        updated.employmentType = employmentTypes.find(f => f.symbol === updated.employmentType)?.id ?? employmentTypes.find(f => f.symbol === EmploymentType.NOT_SPECIFIED)?.id;

        if (updatedObject?.roleSymbol && updatedObject?.roleSymbol !== currentUser?.roleSymbol) {
            await updateRole(updatedObject?.roleSymbol).finally(() => update(updated))
        } else {
            await update(updated);
        }

        setEditMode(false);
    }

    const handleCancel = () => {
        const user = users.find(s => s.id === cUId);
        if (user == null) return;
        setUpdatedObject({...user});
        setEditMode(false);
    }

    if (loading || updatedObject == null || isUpdating || isUpdatingRole) {
        return (
            <MainContainer>
                <HeaderContainer title={t('User.MANAGE_STAFF_1')}></HeaderContainer>
                <LoadingSpinner/>
            </MainContainer>
        )
    }
    const isCurrentUserLoggedInUser = !checkRole(Role.SUPERADMIN) && updatedObject.userId === user.userId;
    return (
        <MainContainer>
            <HeaderContainer title={t('User.MANAGE_STAFF_2')}></HeaderContainer>
            <Tabs
                activeKey={activeKey}
                transition={false}
                className="ms-5 me-5 shadow"
                justify
                onSelect={(key) => setActiveKey(key as ActiveKeys)}

            >
                <Tab eventKey={ActiveKeys.USER} title={t('User.INFORMATION_1')}>
                    <ContentContainer>


                        <UserRow title={t('User.PERSONAL_INFORMATION_1')}>
                            <InternalInput name={"name"} title={t('User.NAME_1')} controlId={'formFirstName'}
                                           commonProps={commonProps}
                                           disabled/>
                            {!updatedObject.isExternal ??
                                <InternalInput name={"email"} title={t('User.E_MAIL_1')} controlId={'formEmail'}
                                               type={"email"}
                                               disabled
                                               commonProps={commonProps}/>
                            }
                            <InternalInput
                                name={"phone"}
                                title={t('User.PHONE_1')}
                                controlId={'formPhone'}
                                commonProps={commonProps}
                            />
                            <InternalInput
                                name={"mobile"}
                                title={t('User.MOBILE_1')}
                                controlId={'formPhone'}
                                commonProps={commonProps}
                            />
                        </UserRow>
                        <UserRow title={t('User.ADDRESS_1')}>
                            <InternalInput name={"street"} title={t('User.HOME_ADDRESS_1')} controlId={'formStreet'}
                                           commonProps={commonProps}/>
                            <InternalInput name={"zipcode"} title={t('User.ZIP_CODE_1')} controlId={'formZipCode'}
                                           commonProps={commonProps}/>
                            <InternalInput name={"city"} title={t('User.LOCATION_1')} controlId={'formAddress'}
                                           commonProps={commonProps}/>
                            <InternalInput name={"privatePhone"} title={t('User.PHONE_PRIVATE_1')}
                                           controlId={'formPrivatePhone'}
                                           commonProps={commonProps}/>
                        </UserRow>


                        <UserRow title={t('User.EMPLOYMENT_INFORMATION_1')}>
                            <InternalInput name={"position"} title={t('User.POSITION_1')} controlId={'formPosition'}
                                           commonProps={commonProps}/>
                            <InternalInput name={"employmentNumber"} title={t('User.EMPLOYMENT_NUMBER_1')}
                                           controlId={'formEmploymentNumber'} commonProps={commonProps}/>
                            <FormBase row={false} controlId={'formEmploymentType'} title={t('User.TYPE_OF_EMPLOYMENT_1')} labelProps={{ style: { fontWeight: 500 } }}
                                      className={'pb-2'}>
                                <When condition={!viewMode}
                                      otherwise={t(employmentTypeMap[(updatedObject as any).employmentType as EmploymentType]) ?? '-'}>
                                    <GenericInput type={'select'} name={'employmentType'} {...commonProps}>
                                        {employmentTypes.map((v, index) => <option defaultChecked={index === 0}
                                                                                   value={v.symbol}>{t(employmentTypeMap[v.symbol])}</option>)}
                                    </GenericInput>
                                </When>
                            </FormBase>
                            <FormBase row={false} controlId={'formPositionLevels'} title={t('User.POSITION_LEVELS_1')} labelProps={{ style: { fontWeight: 500 } }}
                                className={'pb-2'}>
                                <When condition={updatedObject.positionLevels.length > 0} otherwise={'-'}>
                                    <span>{updatedObject.positionLevels.map(i => i.name).join(', ')}</span>
                                </When>
                            </FormBase>
                        </UserRow>

                        <UserRow title={t('User.OTHER_1')}>
                            <InternalInput name={"relatives"} title={t('User.RELATIVES_1')} controlId={'formRelatives'}
                                           type={"textarea"}
                                           commonProps={commonProps}/>
                            <span/>
                            <InternalInput name={"additionalInformation"} title={t('User.OTHER_INFORMATION_1')}
                                           controlId={'formAdditionalInformation'} type={"textarea"}
                                           commonProps={commonProps}/>
                            <span/>
                        </UserRow>


                        <Hr title={t('User.ROLE_1')}>
                            <When condition={editMode} otherwise={<span>{t(userRoleMap[updatedObject.roleSymbol])}</span>}>
                                <FormBase row={false} controlId={'formRole'} title={t('User.ROLE_2')} labelProps={{ style: { fontWeight: 500 } }}
                                          className={'pb-2'}>
                                    <GenericInput type={'select'} name={'roleSymbol'} {...commonProps}
                                                  disabled={isCurrentUserLoggedInUser || !updatedObject.isExternal}>
                                        <option value={CompanyRole.READER}>{t('User.READER_1')}</option>
                                        <option value={CompanyRole.EDITOR}
                                                disabled={!checkAccess(CompanyRole.ADMIN)}>{t('User.EDITOR_1')}
                                        </option>
                                        <option value={CompanyRole.ADMIN}
                                                disabled={!checkAccess(CompanyRole.ADMIN)}>{t('User.ADMIN_1')}
                                        </option>
                                    </GenericInput>
                                    <When condition={isCurrentUserLoggedInUser}>
                                        <span style={{color: "#842029"}}>{t('User.NOT_ALLOWED_TO_CHANGE_OWN_ROLE_1')}</span>
                                    </When>
                                    <When condition={!updatedObject.isExternal}>
                                        <span style={{color: "#842029"}}>{t('User.NOT_ALLOWED_TO_CHANGE_EXTERNAL_USER_1')}</span>
                                    </When>
                                </FormBase>
                            </When>

                        </Hr>


                        <hr/>
                        <Row style={{justifyContent: 'flex-end'}}>
                            <When condition={!editMode}>
                                <Button style={{width: '200px'}}
                                        onClick={() => setEditMode(true)}>{t('User.EDIT_1')}</Button>
                            </When>
                            <When condition={editMode}>
                                <Button style={{width: '200px', marginRight: 5}}
                                        onClick={handleCancel}>{t('User.CANCEL_1')}</Button>
                                <Button style={{width: '200px'}}
                                        onClick={handleUpdate}>{t('User.SAVE_1')}</Button>


                            </When>
                        </Row>
                    </ContentContainer>
                </Tab>
                <Tab eventKey={ActiveKeys.FILES} title={t('User.DOCUMENT_1')} disabled={editMode}>
                    <ContentContainer>
                        <FileTable files={updatedObject.attachments} companyUserId={cUId!}/>
                    </ContentContainer>
                </Tab>
            </Tabs>

        </MainContainer>

    )
}
export default User
