import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetEmploymentTypes, useUpdateCompanyUser } from "../../api/CompanyApi";
import { useGetPositionLevels } from "../../api/PositionLevelApi";
import CustomMultiSelect from "../../components/FormComponents/CustomMultiSelect";
import FormBase from "../../components/FormComponents/FormBase";
import GenericInput from "../../components/FormComponents/GenericInput";
import ModalForm from "../../components/FormComponents/ModalForm";
import When from "../../components/When";
import { EmploymentType } from "../../core/enums/enums";
import { ICompanyInfo, ICompanyUserExtended } from "../../core/interfaces/company";



const employmentTypeMap: Record<EmploymentType, string> = {
    [EmploymentType.NOT_SPECIFIED]: 'EditCompanyUserModal.NOT_SPECIFIED_1',
    [EmploymentType.NOT_EMPLOYED]: 'EditCompanyUserModal.NOT_EMPLOYED_1',
    [EmploymentType.HIRED]: 'EditCompanyUserModal.HIRED_1',
    [EmploymentType.CONSULTANT]: 'EditCompanyUserModal.CONSULTANT_1',
    [EmploymentType.APPRENTICE]: 'EditCompanyUserModal.APPRENTICE_1',
    [EmploymentType.INTERNSHIP]: 'EditCompanyUserModal.INTERNSHIP_1',
    [EmploymentType.PROJECT_EMPLOYMENT]: 'EditCompanyUserModal.PROJECT_EMPLOYMENT_1',
    [EmploymentType.PROBATIONARY_EMPLOYMENT]: 'EditCompanyUserModal.TRIAL_EMPLOYMENT_1',
    [EmploymentType.SEASONAL_WORKER]: 'EditCompanyUserModal.SEASONAL_EMPLOYMENT_1',
    [EmploymentType.PERMANENT]: 'EditCompanyUserModal.INDEFINITELY_1',
    [EmploymentType.HOURLY_EMPLOYEE]: 'EditCompanyUserModal.PART_TIME_EMPLOYEE_1',
    [EmploymentType.SUBSTITUTE]: 'EditCompanyUserModal.SUBSTITUTE_1',
    [EmploymentType.FIXED_TERM_EMPLOYMENT]: 'EditCompanyUserModal.FIXED_TERM_EMPLOYMENT_1'
};

const Hr: React.FC<{ title: string, children: JSX.Element | JSX.Element[] }> = ({ title, children }) => {
    return <><Row>
        <Col sm={1}>
            <b>{title}</b>
        </Col>
        <Col sm={11}>
            <hr />
        </Col>
    </Row>
        <Row style={{ marginLeft: 1 }}>
            {children}
        </Row>
    </>

}
const EditcompanyUserModal: React.FC<{
    show: boolean,
    hide: () => void,
    currentUser: ICompanyUserExtended | null,
    viewMode?: boolean
}> = ({
          show,
          hide,
          currentUser,
          viewMode = false
      }) => {
    const {t} = useTranslation();
    const [update, isUpdating] = useUpdateCompanyUser(currentUser?.userId ?? '');

        const [updatedObject, setUpdatedObject] = useState({ ...currentUser });
        const commonProps = {
            isEditMode: !viewMode,
            object: updatedObject,
            setValue: setUpdatedObject,
        }
        const [employmentTypes] = useGetEmploymentTypes();

        const [positionLevels] = useGetPositionLevels();

        const onChange = (e: { value: string, label: string }[]) => {
            setUpdatedObject({
                ...currentUser,
                positionLevels: e.map(v => {
                    return { id: v.value, name: v.label };
                })
            })
        };

        useEffect(() => {
            setUpdatedObject({ ...currentUser })
        }, [currentUser])


        if (currentUser == null) return <></>

    return (
        <ModalForm size={'lg'} show={show} hide={hide} title={viewMode ? "" : t('EditCompanyUserModal.EDIT_USER_1')}
                   isEdited={() => false}
                   submit={() => {
                       const updated = _.cloneDeep(updatedObject) as ICompanyInfo;
                       updated.employmentType = employmentTypes.find(f => f.symbol === updated.employmentType)?.id ?? employmentTypes.find(f => f.symbol === EmploymentType.NOT_SPECIFIED)?.id;
                       !viewMode && update(updated).then(_ => hide())
                   }}
                   submitText={t('EditCompanyUserModal.SAVE_1')} disableSubmit={viewMode}
                   isLoading={isUpdating}>
            <Hr title={t('EditCompanyUserModal.PERSONAL_INFORMATION_1')}>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formFirstName'} title={t('EditCompanyUserModal.NAME_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'name'} {...commonProps} disabled/>
                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formEmail'} title={t('EditCompanyUserModal.EMAIL_1')}
                                  className={'pb-2'}>
                            <GenericInput type={'email'} name={'email'} {...commonProps} disabled/>
                        </FormBase>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formPhone'} title={t('EditCompanyUserModal.PHONE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'phone'} {...commonProps}  />
                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formPhone'} title={t('EditCompanyUserModal.MOBILE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'mobile'} {...commonProps}  />
                        </FormBase>
                    </Col>
                </Row>
            </Hr>
            <Hr title={t('EditCompanyUserModal.ADDRESS_1')}>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formStreet'} title={t('EditCompanyUserModal.HOME_ADDRESS_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'street'} {...commonProps}  />
                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formZipCode'} title={t('EditCompanyUserModal.ZIP_CODE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'zipcode'} {...commonProps}  />
                        </FormBase>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormBase row={false} controlId={'formAddress'} title={t('EditCompanyUserModal.LOCATION_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'city'} {...commonProps}  />

                        </FormBase>
                    </Col>
                    <Col>
                        <FormBase row={false} controlId={'formPhone'} title={t('EditCompanyUserModal.PHONE_PRIVATE_1')}
                                  className={'pb-2'}>
                            <GenericInput name={'privatePhone'} {...commonProps}  />
                        </FormBase>
                    </Col>
                </Row>
            </Hr>

            <Hr title={t('EditCompanyUserModal.EMPLOYMENT_INFORMATION_1')}>
                <>
                    <Row>
                        <Col>
                            <FormBase row={false} controlId={'formPosition'} title={t('EditCompanyUserModal.POSITION_1')}
                                      className={'pb-2'}>
                                <GenericInput name={'position'} {...commonProps}  />
                            </FormBase>
                        </Col>
                        <Col>
                            <FormBase row={false} controlId={'formEmploymentNumber'} title={t('EditCompanyUserModal.EMPLOYMENT_NUMBER_1')}
                                      className={'pb-2'}>
                                <GenericInput name={'employmentNumber'} {...commonProps}  />
                            </FormBase>
                        </Col>
                    </Row>
                    <Row>

                            <Col>
                                <FormBase row={false} controlId={'formEmploymentType'} title={t('EditCompanyUserModal.TYPE_OF_EMPLOYMENT_1')}
                                    className={'pb-2'}>
                                    <When condition={!viewMode}
                                        otherwise={t(employmentTypeMap[(currentUser as any).employmentType as EmploymentType]) ?? '-'}>
                                        <GenericInput type={'select'} name={'employmentType'} {...commonProps}>
                                            {employmentTypes.map((v, index) => <option defaultChecked={index === 0}
                                                value={v.symbol}>{t(employmentTypeMap[v.symbol])}</option>)}
                                        </GenericInput>
                                    </When>
                                </FormBase>
                            </Col>
                            <Col>
                                <FormBase row={false} controlId={'formPositionLevels'} title={t('EditCompanyUserModal.POSITION_LEVELS_1')}
                                    className={'pb-2'}>
                                    <CustomMultiSelect
                                        isEditMode={!viewMode}
                                        name={"positionLevels"}
                                        selected={(positionLevels ?? []).filter(p => currentUser.positionLevels.some(pl => pl.id === p.id)).map(p => ({ value: p.id, label: p.name })) ?? []}
                                        options={(positionLevels ?? []).map(p => ({ value: p.id, label: p.name })) ?? []}
                                        onChange={onChange}
                                    />
                                </FormBase>
                            </Col>
                        </Row>
                    </>
                </Hr>
                <Hr title={t('EditCompanyUserModal.OTHER_1')}>
                    <>
                        <FormBase row={false} controlId={'formRelatives'} title={t('EditCompanyUserModal.RELATIVES_1')}
                            className={'pb-2'}>
                            <GenericInput name={'relatives'} {...commonProps} type={'textarea'} />
                        </FormBase>
                        <FormBase row={false} controlId={'formAdditionalInformation'} title={t('EditCompanyUserModal.ADDITIONAL_INFORMATION_1')}
                            className={'pb-2'}>
                            <GenericInput name={'additionalInformation'} {...commonProps} type={'textarea'} />
                        </FormBase>
                    </>
                </Hr>


            <When condition={viewMode}>
                <Hr title={t('EditCompanyUserModal.ROLE_1')}>
                    <span>{currentUser.role}</span>

                    </Hr>
                </When>
            </ModalForm>
        )
    }

export default EditcompanyUserModal
