import { useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCreateNewModule } from "../../../api/DocumentApi";
import { Icon, IconSize, getIcon } from "../../../assets/Icons/IconClassNames";
import { ModuleType, TemplateType } from "../../../core/enums/enums";
import { IDocumentModuleCreateReq } from "../../../core/interfaces/document";
import CreateFromTemplateModal from "../../DocumentDirectory/Components/CreateFromTemplateModal";
import styles from "./documentModules.module.scss";

interface IAddDocumentModulesContainer {
    documentId: string;
}

const AddDocumentModulesContainer = ({documentId}: IAddDocumentModulesContainer) => {
    const {t} = useTranslation();
    const [showModulesCanvas, setShowModulesCanvas] = useState(false);
    const [showAddChecklistFromTemplate, setShowAddChecklistFromTemplate] = useState(false);

    // Create new module
    const [createNewModule, isCreatingNewModule] = useCreateNewModule(documentId);
    const handleCreateNewModule = async (module: IDocumentModuleCreateReq) => {
        await createNewModule(module).then((response) => {
            if (response.success) {
                setShowModulesCanvas(false);
            }
        });
    }

    const closeChecklistModal = () => {
        setShowAddChecklistFromTemplate(false);
        setShowModulesCanvas(true);
    }

    const addTextModule = async () => {
        const tempTextModule: IDocumentModuleCreateReq = {
            documentModuleType: ModuleType.TEXT
        };
        await handleCreateNewModule(tempTextModule);
    }

    async function addChecklistModule() {
        if (isCreatingNewModule) return;
        const moduleReq: IDocumentModuleCreateReq = {
            documentModuleType: ModuleType.CHECKLIST,
        }

        await handleCreateNewModule(moduleReq);
    }

    const renderCanvasCards = () => (
        <div className={styles.canvasCardContainer}>
            <div className={styles.canvasCard} onClick={addTextModule}>
                <div className={styles.canvasCardIcon}>
                    <span className={getIcon(Icon.EDIT, IconSize.X4)}/>
                </div>
                <div className={styles.canvasCardFooter}>{t('AddDocumentModulesContainer.TEXT_1')}</div>
            </div>

            <div className={styles.canvasCard} onClick={() => addChecklistModule()}>
                <div className={styles.canvasCardIcon}>
                    <span className={getIcon(Icon.CHECK_CIRCLE, IconSize.X4)}/>
                </div>
                <div className={styles.canvasCardFooter}>{t('AddDocumentModulesContainer.CHECKLIST_1')}</div>
            </div>

            {/*<div className={styles.canvasCard} onClick={() => openChecklistModal(true)}>*/}
            {/*    <div className={styles.canvasCardIcon}>*/}
            {/*        <span className={getIcon(Icon.CHECK_CIRCLE, IconSize.X4)}/>*/}
            {/*    </div>*/}
            {/*    <div className={styles.canvasCardFooter}>{t('AddDocumentModulesContainer.CHECKLIST_FROM_TEMPLATE_1')}</div>*/}
            {/*</div>*/}
        </div>
    )


    const handleSetShowAddChecklistFromTemplateModal = (value: boolean) => {
        if (value) {
            closeChecklistModal();
        } else {
            setShowAddChecklistFromTemplate(false);
        }
    }

    return (<>
        <div className={styles.addModuleBox} onClick={() => setShowModulesCanvas(true)}>
            <div><span className={getIcon(Icon.CUBES, IconSize.LARGE)}/><span style={{fontSize: "18px"}}>{t('AddDocumentModulesContainer.ADD_MODULE_1')}</span>
            </div>
        </div>
        <Offcanvas className={styles.offCanvasSize} show={showModulesCanvas} onHide={() => setShowModulesCanvas(false)}
                   placement="end">
            <OffcanvasHeader closeButton>
                <Offcanvas.Title>{t('AddDocumentModulesContainer.MODULES_1')}</Offcanvas.Title>
            </OffcanvasHeader>
            <OffcanvasBody>
                {renderCanvasCards()}
            </OffcanvasBody>
        </Offcanvas>

        {showAddChecklistFromTemplate &&
            <CreateFromTemplateModal
                templateType={TemplateType.CHECKLIST}
                visible={showAddChecklistFromTemplate}
                setVisible={handleSetShowAddChecklistFromTemplateModal}
            />
        }
    </>)
};

export default AddDocumentModulesContainer;
