import i18n from "i18next";
import { ChangeEvent, useMemo } from "react";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";

interface IDataTableFilter {
    filterText: string;
    setFilterText: (value: string) => void;
    inputPlaceholder: string;
}

const DataTableFilter = ({
    filterText,
    setFilterText,
    inputPlaceholder,
}: IDataTableFilter) => useMemo(() => {

    const handleClear = () => {
        if (filterText) {
            setFilterText('');
        }
    };

    const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
    }

    return (
        <Col md={{ span: 6, offset: 6 }}>
            <InputGroup>
                <Form.Control
                    type="text"
                    size="sm"
                    onChange={onFilterChange}
                    value={filterText}
                    placeholder={inputPlaceholder}
                    aria-label={inputPlaceholder}
                    aria-describedby={`${inputPlaceholder}-clear-btn`}
                />
                <Button id={`${inputPlaceholder}-clear-btn`} variant="outline-secondary" onClick={handleClear}>{i18n.t('DataTableFilter.CLEAR_1')}</Button>
            </InputGroup>
        </Col>
    )

}, [filterText, setFilterText, inputPlaceholder]);

export default DataTableFilter;